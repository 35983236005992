import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { PiCity } from "react-icons/pi";
import { imgUrl } from "../../../utilities/config";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ListCard = ({ data, isSelected, onClick }) => {
  console.log(data);
  const navigate = useNavigate();
  return (
    <Box
      bg={isSelected ? "#784FCF" : "#EBEBEB"} // Change background for selected cards
      p={5}
      borderRadius={"12px"}
      shadow={"md"}
      border={isSelected ? "2px solid #784FCF" : "none"} // Highlight selected cards
      cursor="pointer"
      onClick={onClick} // Handle click
    >
      <Flex align="center" justify="space-between" gap={4}>
        {/* Image */}
        <Box w="60px" h="60px" flexShrink={0}>
          <Image
            borderRadius={"8px"}
            alt="image"
            w="100%"
            h="100%"
            src={
              data?.image
                ? `${imgUrl}/${data?.image}`
                : "https://www.shutterstock.com/image-illustration/no-picture-available-placeholder-thumbnail-600nw-2179364083.jpg"
            }
          />
        </Box>

        {/* Name and Address */}
        <Box flex="1">
          <Heading
            textTransform={"capitalize"}
            fontSize={"17px"}
            fontWeight={600}
            color={isSelected ? "#fff" : "#111"}
            mb={1}
          >
            {data?.name}
          </Heading>
          <Flex align="center" gap={2}>
            <Icon as={PiCity} color={isSelected ? "#fff" : "#111"} />
            <Text
              fontSize="14px"
              color={isSelected ? "#fff" : "#111"}
              fontWeight={600}
              isTruncated
            >
              {`${data?.address}, ${data?.city}`}
            </Text>
          </Flex>
        </Box>

        {/* Added Date */}
        <Box textAlign="center">
          <Text
            fontSize={"14px"}
            fontWeight={"500"}
            color={isSelected ? "#fff" : "#75767A"}
          >
            <Text
              as={"span"}
              color={isSelected ? "#fff" : "#111"}
              fontWeight={600}
              fontSize={"13px"}
            >
              Added Date
            </Text>{" "}
            : {moment(data?.createdAt).format("DD/MM/YYYY")}
          </Text>
        </Box>

        {/* View Details Button */}
        <Box>
          <Button
            bg={"#EBEBEB"}
            px={{ base: 4, lg: 6 }}
            border={"1px solid"}
            fontSize={"13px"}
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering card click
              navigate(`/dashboard/daycaresDetail/${data?._id}`);
            }}
          >
            View Details
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default ListCard;
