import React, { useEffect, useState } from "react";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import Card from "../../components/Dashboard/Card/Card";
import { Button, Stack, Text } from "@chakra-ui/react";
import { GET } from "../../utilities/ApiProvider";
import Loader from "../../components/Dashboard/Loader/Loader";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Dashboard/Pagination";

function DayCares() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const getAllDayCares = async (page) => {
    try {
      const response = await GET(`/daycare/paginate?page=${page}&limit=10`);
      console.log("API Response:", response);
      if (response?.statusCode === 200) {
        // setData([]);
        setData(response?.data?.dayCares || []);
        setTotalPages(response?.data?.pagination?.totalPages || 1); // Updated field path
      } else {
        setData([]);
      }
    } catch (error) {
      console.log("API Error:", error);
    }
  };

  useEffect(() => {
    getAllDayCares(currentPage);
  }, [currentPage]);

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <CustHeading
          name={"All Day Care’s"}
          // subHeading={"Here is the daycare's list....Check Now!"}
        />
        <Stack direction="row">
          <Button
            mr={3}
            bg={"#784FCF"}
            color={"#fff"}
            fontSize={"16px"}
            fontWeight={"500"}
            _hover={{
              bg: "#784FCF",
              color: "#fff",
            }}
            _active={{
              bg: "#784FCF",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/add-new-daycare")}
            w="187px"
            h="40px"
            borderRadius={"5.5px"}
          >
            Add New Day Care
          </Button>
          <Button
            mr={3}
            bg={"#784FCF"}
            color={"#fff"}
            fontSize={"16px"}
            fontWeight={"500"}
            _hover={{
              bg: "#784FCF",
              color: "#fff",
            }}
            _active={{
              bg: "#784FCF",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/list-daycares")}
            w="187px"
            h="40px"
            borderRadius={"5.5px"}
          >
            Handle Day Cares
          </Button>
        </Stack>
      </Stack>

      {!data ? (
        <Loader />
      ) : data?.length > 0 ? (
        <>
          <Stack>
            <Card data={data} />
          </Stack>
          <Stack mt={4} direction="row" spacing={4} justifyContent="center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </Stack>
        </>
      ) : (
        <>
          <Text fontSize={"20px"} fontWeight={"500"}>
            No Day Cares Found
          </Text>
        </>
      )}
    </>
  );
}

export default DayCares;
