import {
  Box,
  Button,
  Checkbox,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { DELETE, GET } from "../../utilities/ApiProvider";
import Loader from "../../components/Dashboard/Loader/Loader";
import ListCard from "../../components/Dashboard/Card/ListCard";
import moment from "moment";

const ListDayCares = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [applyingFilter, setApplyingFilter] = useState(false);
  const [date, setDate] = useState("");

  const applyFilter = () => {
    console.log("Applying filter", date);
    let filters = "";
    setApplyingFilter(true);
    if (!date) {
      toast({
        title: "Please select a date to apply filter",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }
    filters += `date=${date}`;
    fetchData(filters);
  };

  const clearFilter = () => {
    setApplyingFilter(true);
    setDate(null);
  };

  const deleteDayCares = async () => {
    try {
      const response = await DELETE(`/daycare/delete-by-date?date=${date}`);
      if (response?.success === true || response?.statusCode === 200) {
        toast({
          title: `${response?.data?.deletedCount} Daycare deleted successfully`,
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        let filters = `date=${date}`;
        fetchData(filters);
        onClose();
      } else {
        toast({
          description: `${response?.message}`,
          status: "error",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchData(filters = "") {
    try {
      setLoading(true);

      // Construct the URL with filters
      const url = `/daycare/get-count?${filters}`;

      const response = await GET(url);

      window.scrollTo({ top: 0, behavior: "smooth" });
      if (response?.success === true || response?.statusCode === 200) {
        console.log(response.data.toLocaleString());
        setData(response.data.toLocaleString());
        setLoading(false);
        setApplyingFilter(false);
        setIsFiltering(false);
      } else {
        setLoading(false);
        toast({
          description: `${response?.message}`,
          status: "error",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"start"}
      >
        <CustHeading name={"List Day Care’s"} />
        <Stack direction="row">
          {data && (
            <Button
              mr={3}
              bg={"#784FCF"}
              color={"#fff"}
              fontSize={"16px"}
              fontWeight={"500"}
              _hover={{
                bg: "#784FCF",
                color: "#fff",
              }}
              _active={{
                bg: "#784FCF",
                color: "#fff",
              }}
              onClick={onOpen}
              w="187px"
              h="40px"
              borderRadius={"5.5px"}
            >
              Delete All
            </Button>
          )}
        </Stack>
        <Stack width={"30%"}>
          <Stack my={4}>
            <Button onClick={() => setIsFiltering(!isFiltering)}>
              Filters{" "}
              {isFiltering ? (
                <ChevronUpIcon mx={2} />
              ) : (
                <ChevronDownIcon mx={2} />
              )}
            </Button>
          </Stack>
          {isFiltering && (
            <Stack
              width={"100%"}
              gap={4}
              px={3}
              boxShadow="xl"
              borderRadius={8}
              py={6}
            >
              <Stack>
                <Heading color={"#409D47;"} fontSize={"18px"} fontWeight={700}>
                  Date
                </Heading>
                <Stack flexDirection="row" alignItems="end" gap={4}>
                  <Input
                    type="date"
                    value={date}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  />
                </Stack>
              </Stack>

              <Stack flexDirection={"row"} alignItems={"end"} gap={4}>
                <Button
                  border={"2px solid #409D47"}
                  borderRadius={"25px"}
                  bg={
                    "var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))"
                  }
                  onClick={applyFilter}
                >
                  Apply Filter
                </Button>
                <Button
                  width={"max-content"}
                  border={"2px solid #409D47"}
                  borderRadius={"25px"}
                  bg={"crimson"}
                  onClick={clearFilter}
                >
                  Clear Filter
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      {loading ? (
        <Loader />
      ) : (
        <Stack>
          <Box p={4}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
              {data ? (
                <Stack>
                  <Heading>
                    You Have {data} Daycares Created on{" "}
                    {moment(date).format("DD/MM/YYYY")}
                  </Heading>
                </Stack>
              ) : (
                <Heading>No data found</Heading>
              )}
            </SimpleGrid>
          </Box>
        </Stack>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Day Cares</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={"18px"} fontWeight={500}>
              Are you sure you want to delete all day cares?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              mr={3}
              bg={"#784FCF"}
              color={"#fff"}
              fontSize={"16px"}
              fontWeight={"500"}
              _hover={{
                bg: "#784FCF",
                color: "#fff",
              }}
              _active={{
                bg: "#784FCF",
                color: "#fff",
              }}
              w="187px"
              h="40px"
              borderRadius={"5.5px"}
              onClick={deleteDayCares}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ListDayCares;
