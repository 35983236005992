import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { PiCity } from "react-icons/pi";
import { imgUrl } from "../../../utilities/config";

function Card({ data }) {
  const navigate = useNavigate();
  console.log(data);

  return (
    <SimpleGrid columns={{ base: 1, md: 2, xl: 2, "2xl": 3 }} spacing={10}>
      {data?.map((item, ind) => (
        <Box bg={"#EBEBEB"} p={5} borderRadius={"12px"} shadow={"md"}>
          <Stack
            flexDirection={{
              base: "column",
              md: "column",
              xl: "row",
              "2xl": "row",
            }}
            justifyContent={"space-between"}
            alignItems={{
              base: "start",
              md: "start",
              xl: "center",
              "2xl": "center",
            }}
          >
            <HStack d>
              <Box w="60px" h="60px">
                <Image
                  borderRadius={"8px"}
                  alt="image"
                  w="100%"
                  h="100%"
                  src={
                    item?.image
                      ? `${imgUrl}/${item?.image}`
                      : "https://www.shutterstock.com/image-illustration/no-picture-available-placeholder-thumbnail-600nw-2179364083.jpg"
                  }
                />
              </Box>
              <Box>
                <Heading
                  textTransform={"capitalize"}
                  fontSize={"17px"}
                  fontWeight={600}
                  noOfLines={1}
                  w={{
                    base: "100%",
                    md: "150px",
                    xl: "250px",
                    "2xl": "250px",
                  }}
                >
                  {item?.name || item?.name}
                </Heading>
                <Stack
                  flexDirection="row"
                  mt={1}
                  alignItems="center"
                  spacing={2}
                >
                  <Icon as={PiCity} />
                  <Text
                    marginLeft={"-5px"}
                    fontSize="14px"
                    color={"black"}
                    fontWeight={600}
                    noOfLines={1}
                    w={"150px"}
                  >
                    {item?.city}
                  </Text>
                </Stack>
              </Box>
            </HStack>
            <Box>
              <Button
                bg={"#EBEBEB"}
                px={{ base: 2, lg: 4 }}
                border={"1px solid"}
                fontSize={"13px"}
                onClick={() =>
                  navigate(`/dashboard/daycaresDetail/${item?._id}`)
                }
              >
                View Details
              </Button>
            </Box>
          </Stack>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Text fontSize={"14px"} mt={4} fontWeight={"500"} color={"#75767A"}>
              <Text
                as={"span"}
                color={"#111"}
                fontWeight={600}
                fontSize={"13px"}
              >
                Added Date
              </Text>{" "}
              : {moment(item?.createdAt).format("DD/MM/YYYY")}
            </Text>
            {/* <Text fontSize={"16px"} mt={4} fontWeight={"500"} color={"#75767A"}>
              <Text as={'span'} color={'#111'} fontWeight={600}   fontSize={'13px'}>Price</Text>  :  {item?.price}
            </Text> */}
          </Flex>
        </Box>
      ))}
    </SimpleGrid>
  );
}

export default Card;
