import {
  Box,
  Button,
  FormLabel,
  Icon,
  Image,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { TiDelete } from "react-icons/ti";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import ModalWrapper from "../../components/Dashboard/Modal/ModalWrapper";
import { DELETE, GET, POST, PUT } from "../../utilities/ApiProvider";
import { baseURL, imgUrl } from "../../utilities/config";

function AddNewAgeGroup({ fetchOptions, onClose }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData(e.target);
      formData.append("type", "ageGroup");
      const response = await POST("/daycare/helper", formData);
      console.log(response);
      setLoading(false);
      if (response?.statusCode === 200) {
        fetchOptions();
        toast({
          title: "Age Group Added Successfully",
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form className="applyForm" onSubmit={handleSubmit}>
      <Box>
        <FormLabel fontSize={"16px"} fontWeight={500}>
          Age Group
        </FormLabel>
        <Input name="title" placeholder="Enter here" required />
      </Box>
      <Button
        type="submit"
        my={4}
        bg={"#784FCF"}
        color={"#fff"}
        isLoading={loading}
        fontSize={"16px"}
        fontWeight={"500"}
        _hover={{
          bg: "#784FCF",
          color: "#fff",
        }}
        _active={{
          bg: "#784FCF",
          color: "#fff",
        }}
        w="100%"
        borderRadius={"5.5px"}
      >
        Submit
      </Button>
    </form>
  );
}

function AddNewDayCareType({ fetchOptions, onClose }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData(e.target);
      formData.append("type", "dayCareType");
      const response = await POST("/daycare/helper", formData);
      console.log(response);
      setLoading(false);
      if (response?.statusCode === 200) {
        fetchOptions();
        toast({
          title: "Day Care Type Added Successfully",
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form className="applyForm" onSubmit={handleSubmit}>
      <Box>
        <FormLabel fontSize={"16px"} fontWeight={500}>
          Day Care Type
        </FormLabel>
        <Input name="title" placeholder="Enter here" required />
      </Box>
      <Button
        my={4}
        isLoading={loading}
        type="submit"
        bg={"#784FCF"}
        color={"#fff"}
        fontSize={"16px"}
        fontWeight={"500"}
        _hover={{
          bg: "#784FCF",
          color: "#fff",
        }}
        _active={{
          bg: "#784FCF",
          color: "#fff",
        }}
        w="100%"
        borderRadius={"5.5px"}
      >
        Submit
      </Button>
    </form>
  );
}

function AddNewDayCare() {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [options, setOptions] = useState({});
  const [editData, setEditData] = useState(null);
  const [ageGroups, setAgeGroups] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [services, setServices] = useState([]);
  const [dayCareTypes, setDayCareTypes] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [selectedCSV, setSelectedCSV] = useState(null);
  const toast = useToast();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();
  const [selectedOption, setSelectedOption] = useState("false");

  const handleCheckboxChange = (value) => {
    setSelectedOption(value);
  };

  const handleFileChange = (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    setSelectedCSV(file);

    if (file) {
      // Notify user about the file selection
      toast({
        title: "File selected.",
        description: `You have selected ${file.name}`,
        status: "info",
        duration: 3000,
        isClosable: true,
      });

      uploadCSV(file);
    }
  };

  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("");

  const listenForProgressUpdates = () => {
    // Open a connection to the server's progress endpoint using SSE
    const eventSource = new EventSource(`${baseURL}/daycare/upload/progress`);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setProgress(data.progress);
      setProgressMessage(data.message);

      // If progress reaches 100%, close the connection
      if (data.progress === 100) {
        eventSource.close();
      }
    };

    eventSource.onerror = (error) => {
      console.error("Error with SSE:", error);
      eventSource.close();
    };
  };

  useEffect(() => {
    console.log({ progress, progressMessage });
  }, [progress, progressMessage]);

  const uploadCSV = async (file) => {
    const formData = new FormData();
    formData.append("files", file);
    listenForProgressUpdates();
    try {
      const response = await POST("/daycare/upload-csv", formData);
      console.log(response);
      if (response?.success === true) {
        setIsLoading(false);
        toast({
          title: "File uploaded successfully.",
          description: response.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => navigate("/dashboard/daycares"), 4000);
        // navigate("/dashboard/daycares");

        // Handle result if needed
      } else {
        setIsLoading(false);
        toast({
          title: "Upload failed.",
          description: `There was an error uploading the file: ${response?.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Upload failed.",
        description: `There was an error uploading the file: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("file");
    fileInput.click();
  };
  const handleChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setImage(reader.result);
        console.log(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const getDayCareOptions = async () => {
    try {
      const response = await GET("/daycare/helper");
      console.log(response?.data);
      setOptions(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDayCareOptions();
    if (location?.state?.editData) {
      setEditData(location?.state?.editData);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();

      // Append all necessary form fields
      formData.append("name", e.target.fullname.value);
      formData.append("description", e.target.description.value);
      formData.append("lat", e.target.lat.value);
      formData.append("long", e.target.long.value);
      formData.append("price", e.target.price.value);
      formData.append("address", e.target.address.value);
      formData.append("city", e.target.city.value);
      formData.append("postalCode", e.target.postalCode.value);
      formData.append("capacity", e.target.capacity.value);
      formData.append("ageGroups", JSON.stringify(ageGroups));
      formData.append("dayCareTypes", JSON.stringify(dayCareTypes));

      if (selectedFile) {
        formData.append("image", selectedFile);
      }

      formData.append("licensed", selectedOption);

      let response;
      if (editData) {
        response = await PUT(`/daycare/${editData?._id}`, formData);
      } else {
        response = await POST("/daycare/create", formData);
      }

      console.log(response);

      setLoading(false);

      if (response?.success === true || response?.statusCode === 200) {
        toast({
          title: editData
            ? "Day Care Updated Successfully"
            : "Day Care Added Successfully",
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });

        navigate("/dashboard/daycares");

        e.target.reset();
        setImage("");
        setSelectedFile(null);
      } else {
        toast({
          description: `${response?.message}`,
          status: "error",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await DELETE(`/daycare/helper/${id}`);
      if (response?.statusCode === 200) {
        getDayCareOptions();
        toast({
          title: "Deleted Successfully",
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (editData) {
      console.log("Edit Data", editData);
      // populate the form values with editData

      setAgeGroups(editData.ageGroups);
      setServices(editData.services);
      setSelectedOption(editData.licensed ? "true" : "false");
      setDayCareTypes(editData.dayCareTypes);
      // setSelectedOption(editData.licensed);
      setImage(`${imgUrl}${editData.image}`);
    }
  }, [editData]);

  // const [ageGroups, setAgeGroups] = useState([]);
  // const [dayCareTypes, setDayCareTypes] = useState([]);
  // const [options, setOptions] = useState({});

  // Fetch options and edit data as usual, same as in your existing code

  const handleAgeGroupsChange = (selectedOptions) => {
    setAgeGroups(selectedOptions.map((option) => option.value));
  };

  const handleDayCareTypesChange = (selectedOptions) => {
    setDayCareTypes(selectedOptions.map((option) => option.value));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "0px", // Adjust padding as needed
      minHeight: "30px", // Set a smaller height if needed
      borderRadius: "10px", // Change border radius
      borderColor: "#000000", // Change border color
      boxShadow: "none", // Remove default box-shadow
      "&:hover": {
        borderColor: "#784FCF", // Change border color on hover
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#e0e0e0", // Optional: customize selected options background
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#333", // Optional: customize selected options text color
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#666",
      ":hover": {
        backgroundColor: "#ddd",
        color: "#333",
      },
    }),
  };

  return (
    <>
      <CustHeading
        name={"Add New Day Care"}
        subHeading={
          "Enter the details to add new day care or you can import csv file"
        }
      />
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={"Add New Age Group"}
        subTitle={
          "Please be patient; it will proceed after fetching all the details."
        }
        children={
          <AddNewAgeGroup fetchOptions={getDayCareOptions} onClose={onClose} />
        }
      />

      <ModalWrapper
        isOpen={isOpen3}
        onClose={onClose3}
        title={"Add New Day Care Type"}
        subTitle={
          "Please be patient; it will proceed after fetching all the details."
        }
        children={
          <AddNewDayCareType
            fetchOptions={getDayCareOptions}
            onClose={onClose3}
          />
        }
      />
      <SimpleGrid columns={2} spacing={10}>
        <Stack>
          <form className="applyForm" onSubmit={handleSubmit}>
            <Box mb="10px" w={"100%"}>
              <FormLabel fontSize={"16px"} fontWeight={500}>
                Day Care Name*
              </FormLabel>
              <Input
                name="fullname"
                defaultValue={editData && editData.name}
                placeholder="Enter here"
                required
              />
            </Box>
            <Box mb="10px" w={"100%"}>
              <FormLabel fontSize={"16px"} fontWeight={500}>
                Add Picture*
              </FormLabel>
              <Stack direction={"row"} spacing={5}>
                <Stack>
                  <Box
                    w="155px"
                    height={"58px"}
                    bg="#ECECEC"
                    borderRadius={"10px"}
                    border={"1px dashed"}
                    fontSize={"16px"}
                    fontWeight={500}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    cursor={"pointer"}
                    onClick={handleClick}
                  >
                    Upload File
                  </Box>
                  <Input
                    onChange={handleChange}
                    display={"none"}
                    type="file"
                    name="file"
                    id="file"
                  />
                </Stack>
                {image && (
                  <Stack pos={"relative"}>
                    <Image w="155px" height={"65px"} src={image} alt="image" />
                    <Icon
                      pos={"absolute"}
                      top={"-10px"}
                      right={"-10px"}
                      as={TiDelete}
                      w="20px"
                      h="20px"
                      cursor={"pointer"}
                      onClick={() => setImage("")}
                    />
                  </Stack>
                )}
              </Stack>
            </Box>
            <Box mb="10px" w={"100%"}>
              <FormLabel fontSize={"16px"} fontWeight={500}>
                Description*
              </FormLabel>
              <Textarea
                name="description"
                placeholder="Enter here"
                defaultValue={editData && editData.description}
                required
                resize={"none"}
              />
            </Box>
            <Box mb="10px" w={"100%"}>
              <FormLabel fontSize={"16px"} fontWeight={500}>
                Address
              </FormLabel>
              <Input
                name="address"
                defaultValue={editData && editData.address}
                placeholder="Enter here"
                required
              />
            </Box>
            <Box mb="10px" w={"100%"}>
              <FormLabel fontSize={"16px"} fontWeight={500}>
                city
              </FormLabel>
              <Input
                name="city"
                defaultValue={editData && editData.city}
                placeholder="Enter here"
                required
              />
            </Box>
            <Box mb="10px" w={"100%"}>
              <FormLabel fontSize={"16px"} fontWeight={500}>
                postalCode
              </FormLabel>
              <Input
                name="postalCode"
                defaultValue={editData && editData.postalCode}
                placeholder="Enter here"
                required
              />
            </Box>
            <Box mb="10px" w={"100%"}>
              <FormLabel fontSize={"16px"} fontWeight={500}>
                capacity
              </FormLabel>
              <Input
                name="capacity"
                defaultValue={editData && editData.capacity}
                placeholder="Enter capacity Count"
                required
                type="number"
              />
            </Box>
            <Box mb="10px" w={"100%"}>
              <FormLabel fontSize={"16px"} fontWeight={500}>
                Longitude Coordinate Value
              </FormLabel>
              <Input
                name="long"
                defaultValue={editData && editData.long}
                placeholder="Enter here"
                required
                type="text"
              />
            </Box>
            <Box mb="10px" w={"100%"}>
              <FormLabel fontSize={"16px"} fontWeight={500}>
                Latitude Coordinate Value
              </FormLabel>
              <Input
                name="lat"
                defaultValue={editData && editData.lat}
                placeholder="Enter here"
                required
                type="text"
              />
            </Box>
            <Box mb="10px" w={"100%"}>
              <FormLabel fontSize={"16px"} fontWeight={500}>
                $Price
              </FormLabel>
              <Input
                name="price"
                defaultValue={editData && editData.price}
                placeholder="Enter here"
                required
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
              />
            </Box>
            <Text fontSize={"20px"} fontWeight={"500"}>
              Additional Details
            </Text>
            {/* <Box mb="10px" w={"100%"}>
              <Stack
                direction={"row"}
                spacing={5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"18px"} fontWeight={500}>
                  Age Group
                </Text>
                <Button
                  w="123px"
                  h="33px"
                  onClick={onOpen}
                  mr={10}
                  bg="#784FCF"
                  color="white"
                >
                  Add New
                </Button>
              </Stack>
              <SimpleGrid columns={2} spacing={6} name="ageGroups">
                {options?.["ageGroup"]?.map((item, ind) => (
                  <Box>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Stack w={"100%"}>
                        <Stack pos={"relative"}>
                          <Input
                            name="name"
                            readOnly
                            value={`${item?.title} Year`}
                            placeholder="Enter here"
                            required
                          />
                          <Checkbox
                            defaultChecked={editData?.ageGroups?.includes(
                              item?._id
                            )}
                            // isChecked={
                            //   editData?.ageGroups?.includes(item?._id) // Corrected isChecked logic
                            // }
                            onChange={(e) => {
                              console.log(item);
                              if (e.target.checked) {
                                setAgeGroups([...ageGroups, item?._id]);
                              } else {
                                setAgeGroups(
                                  ageGroups.filter(
                                    (items) => items !== item?._id
                                  )
                                );
                              }
                            }}
                            pos={"absolute"}
                            right={"18px"}
                            top={"18px"}
                            colorScheme="purple"
                          ></Checkbox>
                        </Stack>
                      </Stack>
                      <Box
                        w={"28px"}
                        h={"28px"}
                        bg={"#FA2F2F"}
                        borderRadius={"full"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        cursor={"pointer"}
                        onClick={() => handleDelete(item?._id)}
                      >
                        <DeleteIcon color={"#fff"} w={"12px"} h={"14px"} />
                      </Box>
                    </Stack>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>

            <Box mb="10px" w={"100%"}>
              <Stack
                direction={"row"}
                spacing={5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"18px"} fontWeight={500}>
                  Day Care Type
                </Text>
                <Button
                  onClick={onOpen3}
                  w="123px"
                  h="33px"
                  mr={10}
                  bg="#784FCF"
                  color="white"
                >
                  Add New
                </Button>
              </Stack>
              <SimpleGrid columns={2} spacing={6} name="dayCareTypes">
                {options?.["dayCareType"]?.map((item, ind) => (
                  <Box w="100%">
                    <Stack direction={"row"} alignItems={"center"}>
                      <Stack w={"100%"}>
                        <Stack pos={"relative"}>
                          <Input
                            readOnly
                            value={`${item?.title}`}
                            placeholder="Enter here"
                            required
                            fontSize={"14px"}
                          />
                          <Checkbox
                            defaultChecked={editData?.dayCareTypes?.includes(
                              item?._id
                            )}
                            // isChecked={
                            //   editData?.dayCareTypes?.includes(item?._id) // Corrected isChecked logic
                            // }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setDayCareTypes([...dayCareTypes, item?._id]);
                              } else {
                                setDayCareTypes(
                                  dayCareTypes.filter(
                                    (items) => items !== item?._id
                                  )
                                );
                              }
                            }}
                            pos={"absolute"}
                            right={"18px"}
                            top={"18px"}
                            colorScheme="purple"
                          ></Checkbox>
                        </Stack>
                      </Stack>
                      <Box
                        w={"28px"}
                        h={"28px"}
                        bg={"#FA2F2F"}
                        borderRadius={"full"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        cursor={"pointer"}
                        onClick={() => handleDelete(item?._id)}
                      >
                        <DeleteIcon color={"#fff"} w={"12px"} h={"14px"} />
                      </Box>
                    </Stack>
                  </Box>
                ))}
              </SimpleGrid>
            </Box> */}

            <Box mb="10px" w="100%">
              {/* <FormLabel fontSize="16px" fontWeight={500}>
              Age Groups
            </FormLabel> */}
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"18px"} fontWeight={500}>
                  Age Group
                </Text>
                <Button
                  w="123px"
                  h="33px"
                  onClick={onOpen}
                  // mr={10}
                  bg="#784FCF"
                  color="white"
                >
                  Add New
                </Button>
              </Stack>
              <Select
                isMulti
                options={options.ageGroup?.map((item) => ({
                  value: item._id,
                  label: `${item.title} Year`,
                }))}
                value={options.ageGroup
                  ?.filter((item) => ageGroups.includes(item._id))
                  .map((item) => ({
                    value: item._id,
                    label: `${item.title} Year`,
                  }))}
                onChange={handleAgeGroupsChange}
                placeholder="Select age groups"
                styles={customStyles}
              />
            </Box>

            {/* Day Care Types using React Select */}
            <Box mb="10px" w="100%">
              <Stack
                direction={"row"}
                spacing={5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"18px"} fontWeight={500}>
                  Day Care Type
                </Text>
                <Button
                  onClick={onOpen3}
                  w="123px"
                  h="33px"
                  // mr={10}
                  bg="#784FCF"
                  color="white"
                >
                  Add New
                </Button>
              </Stack>
              <Select
                isMulti
                options={options.dayCareType?.map((item) => ({
                  value: item._id,
                  label: item.title,
                }))}
                value={options.dayCareType
                  ?.filter((item) => dayCareTypes.includes(item._id))
                  .map((item) => ({
                    value: item._id,
                    label: item.title,
                  }))}
                onChange={handleDayCareTypesChange}
                styles={customStyles}
                placeholder="Select day care types"
              />
            </Box>
            <Box mb="10px" w={"100%"}>
              <Stack
                direction={"row"}
                spacing={5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"18px"} fontWeight={500}>
                  Choose an option
                </Text>
              </Stack>
              <Stack
                w="100%"
                flexDirection={"row"}
                flexWrap={"wrap"}
                spacing={5}
              >
                <Box mb="10px" w={"100%"}>
                  <FormLabel fontSize={"16px"} fontWeight={500}>
                    Is this daycare licensed?
                  </FormLabel>
                  <RadioGroup
                    onChange={setSelectedOption}
                    defaultValue={selectedOption}
                    value={selectedOption}
                  >
                    <Stack direction="row">
                      <Radio value="true">Licensed</Radio>
                      <Radio value="false">Unlicensed</Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
              </Stack>
            </Box>

            <Button
              type="submit"
              isLoading={loading}
              my={5}
              bg={"#784FCF"}
              color={"#fff"}
              fontSize={"16px"}
              fontWeight={"500"}
              _hover={{
                bg: "#784FCF",
                color: "#fff",
              }}
              _active={{
                bg: "#784FCF",
                color: "#fff",
              }}
              w="187px"
              h="40px"
              borderRadius={"5.5px"}
            >
              Submit
            </Button>
          </form>
        </Stack>
        <Stack>
          {isLoading ? (
            <Stack alignItems={"center"} justifyContent={"center"} gap={6}>
              <Text fontWeight={600}>CSV file is uploading Please wait...</Text>
              <Spinner color="purple.500" size="lg" />
              <Text fontWeight={300} fontSize={"14px"}>
                {progressMessage}
              </Text>
            </Stack>
          ) : (
            <VStack spacing={4} align="start" w="100%">
              <Link
                href="#"
                color="purple.500"
                fontSize="sm"
                onClick={() => document.getElementById("file-input").click()}
              >
                Import CSV file
              </Link>

              <Box
                border="2px dashed"
                borderColor="gray.300"
                bg="gray.50"
                p={6}
                w="100%"
                textAlign="center"
                cursor="pointer"
                onClick={() => document.getElementById("file-input").click()}
              >
                <Text color="gray.600" fontSize="lg">
                  Upload file
                </Text>
              </Box>

              <Input
                id="file-input"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                display="none"
              />
            </VStack>
          )}
        </Stack>
      </SimpleGrid>
    </>
  );
}

export default AddNewDayCare;
